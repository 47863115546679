.bar-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 99999;
  background: rgba(0, 0, 0, 0.25);
}

.bar-content {
  position: absolute;
  width: 100%; // This will be overridden by inline styles in React based on the screen size
  height: 100vh;
  padding: 0.75rem;
  background: rgb(255, 255, 255);
  overflow-y: scroll;
  overflow-x: hidden;
  transition: 0.4s ease-in-out;
}

.bar-visible {
  visibility: visible;
}

.bar-visible.bar-right > div {
  top: 0;
  right: 0;
}

.bar-visible.bar-left > div {
  top: 0;
  left: 0;
}

.bar-collapsed {
  visibility: collapse;
}

.bar-collapsed.bar-right > div {
  top: 0;
  right: -100%;
}

.bar-collapsed.bar-left > div {
  top: 0;
  left: -100%;
}
