.customer {
  cursor: pointer;
  transition: background-color 0.3s ease-in-out;

  &:hover {
    background-color: rgba(161, 121, 0, 1) !important;
    .related-customer-badge {
      background-color: #d39e00 !important;
      color: black;
    }
  }
}

.related-customer-badge {
  background-color: rgba(161, 121, 0, 0.8);
  font-size: 8px;
  font-weight: normal;
  transition: background-color 0.3s ease-in-out;
  padding: 1px 0;
}

.wrapper {
  padding: 1px;
  transition: background-color border-color 0.3 ease-in-out;

  &:hover {
    background-color: rgba(161, 121, 0, 1) !important;
    border-color: rgba(161, 121, 0, 1) !important;
    .customer {
      background-color: rgba(161, 121, 0, 1) !important;
      .related-customer-badge {
        background-color: #d39e00 !important;
        color: black;
      }
    }
  }
}
