@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';

@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:ital,wght@0,200;0,400;0,600;1,200;1,400;1,600&display=swap');

$theme-colors: (
  primary: #d89d31,
  primary-light: #feecc90e,
  primary-dark: rgba(0, 40, 110, 0.176),
  primary-new: #00286e,
  primary-new-light: #e7f0ff,
  light: #fafafa,
  dark: #0c1b33,
  info: #b4f8c8,
  secondary-dark: #989898,
  secondary: #f1f1f1,
  success: #28a745,
  success-neon-2: #00ce00,
  success-neon: #43ff6f,
  danger: #e60707,
  danger-light: #ffc9c9,
  masked-cell: #7cc2ff,
  warning: #ffc107,
  yellow: #ffdd00
);

$border-radius: 0.5rem;
$list-group-active-bg: #d89d31;
$dropdown-link-active-bg: #d89d31;

$btn-focus-width: none;

html,
body,
#root {
  height: 100%;
  font-family: 'IBM Plex Sans', sans-serif;
}

.datepickerPopperClassName {
  z-index: 99999;
}

.hover {
  cursor: pointer;
}

.hover-light:hover {
  background-color: #ffcb639a;
  cursor: pointer;
}

.hover-dark:hover {
  color: white;
  background-color: #ff8e2b;
  cursor: pointer;
  border-radius: 0.5rem;
}

.a {
  cursor: pointer;
  text-decoration: underline;
}

.left-bar {
  border-left: 10px solid #0c2336;
}

.small {
  font-size: 12px;
}

.smallFont {
  font-size: 12px;
}

.smaller {
  font-size: 11px;
}

.mid {
  font-size: 13px;
}

.large {
  font-size: 14px;
}

.xlarge {
  font-size: 16px;
}

.xxlarge {
  font-size: 18px;
}

.tiny {
  font-size: 10px;
}

.xs {
  font-size: 9px;
}

.underline {
  text-decoration: underline;
}

.fade-in {
  animation: fadeIn 1s;
  -webkit-animation: fadeIn 1s;
  -moz-animation: fadeIn 1s;
  -o-animation: fadeIn 1s;
  -ms-animation: fadeIn 1s;
}

.fade-in-quick {
  animation: fadeIn 0.3s;
  -webkit-animation: fadeIn 0.3s;
  -moz-animation: fadeIn 0.3s;
  -o-animation: fadeIn 0.3s;
  -ms-animation: fadeIn 0.3s;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-moz-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-o-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-ms-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.hide-after::after {
  display: none !important;
}

table:not(.no-sticky) > tbody > tr > td:nth-child(1),
table:not(.no-sticky) > thead > tr:nth-child(1) > th:nth-child(1) {
  position: sticky;
  position: -webkit-sticky;
  right: 0;
  left: 0;
  z-index: 1;
  background-color: inherit;
}

.calc-column {
  min-width: 300px;
  max-width: 300px;
}

.chart-container {
  height: 400px;
}

// Target screens smaller than 'md'
$md-breakpoint: map-get($grid-breakpoints, md);

@media (max-width: $md-breakpoint - 1) {
  table > tbody > tr > td:nth-child(1),
  table > thead > tr:nth-child(1) > th:nth-child(1) {
    width: 120px;
    max-width: 120px;
  }

  .calc-column {
    min-width: 200px;
    max-width: 200px;
  }

  .chart-container {
    height: 300px;
  }
}

.expand-collapse-icon {
  position: absolute;
  top: 0;
  right: 0;
  margin: 10px;
  color: $list-group-active-bg;
  cursor: pointer;
}

.masked-cell {
  position: relative;
  overflow: hidden;

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 10%;
    background: map-get($map: $theme-colors, $key: masked-cell); // Light gray transparent mask
    pointer-events: none; // Allows clicks to pass through the mask
  }
}

.table-hover tbody tr:hover td,
.table-hover tbody tr:hover th {
  color: rgb(0, 0, 0);
  background-color: #f1f1f1;
}

.dropdown-toggle.no-caret.btn::after {
  display: none !important;
}

.custom-tooltip {
  background-color: white !important;
  color: black;
  border: 1px solid #ccc;
  border-radius: 5px;
}
.truncate {
  white-space: nowrap; /* Prevents the text from wrapping to the next line */
  overflow: hidden; /* Hides text overflowing the container */
  text-overflow: ellipsis; /* Adds an ellipsis to the end of the text */
}
.sticky-header {
  position: sticky;
  top: 0;
  z-index: 2; /* Ensure it's above other content but below fixed elements like navbars */
  background-color: inherit; /* Or any color matching your design */
}

/* Vertical Scrollbar */
::-webkit-scrollbar {
  width: 20px; /* Adjust to desired width for vertical scrollbar */
  height: 20px; /* Adjust to desired height for horizontal scrollbar */
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.scrollbar-visible::-webkit-scrollbar-thumb {
  background-color: darkgrey !important;
}

.scrollbar-visible::-webkit-scrollbar-track {
  background-color: rgb(241, 241, 241);
}

.scrollbar-visible::-webkit-scrollbar-thumb:hover {
  background-color: grey;
}

.no-pointer-events {
  pointer-events: none;
}

.gap-1 {
  gap: '4px';
}
.gap-2 {
  gap: '8px';
}

@import './form-generator/styles';
@import './components/common/SlidingSideBar/bar';
@import '~bootstrap/scss/bootstrap';
